import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CustomText } from './CustomText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';


const StyledFooter = styled.div`
  border-top: 2px solid #4f3e15;
  // border-bottom: 50px solid #faf0d9;
  left: 0;
  bottom: 0;
  width: 100%;
  height: min-content;

  background-color: #fffaee;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  justify-items: center;
  /* justify-content: center; */
  padding-left: 6vw;
  padding-right: 6vw;
  padding-top: 2rem;
  padding-bottom: 1rem;

  position: relative;
  grid-template-columns: min-content, 1fr, 1fr, 1fr, 1fr;
  column-gap: 4vw;

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
  }


`;
const FooterLinkFlex = styled.div`
  justify-self: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`

const FooterLinks = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  flex-grow: 2;

  text-decoration: none;

  @media (max-width: 900px){
    width: 100%;
  }
  .textLinks {
    list-style-type: none;
    padding: 0px;
    // transform: translateY(+50%);
  }

  .linkHeader {
    color: ${props => props.theme.secondary};
  }

  .linkItem {
    color: var(--wmuc-red);
  }

  .linkItem:hover {
    color: #8c1515;
    text-decoration: none;
    opacity: 1;
  }
  

`;

const FooterSocials = styled.div`
  display: grid;
  row-gap: 1em;
  column-gap: 1em;

  /* @media (min-width: 800px){
    grid-template-columns: min-content min-content min-content min-content;
  } */

  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content;
  height: 5em;
  margin-left: auto;
  @media (min-width: 500px){
    grid-template-columns: min-content min-content min-content min-content;
    height: 2em;
  }



  @media (min-width: 900px){
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content;
    height: 5em;
  }


  /* @media (min-width: 1100){
    grid-template-columns: min-content;
    grid-template-rows: min-content min-content min-content min-content;
    height: 5em;
  } */


  @media (min-width: 1396px) {
    grid-template-columns: min-content min-content min-content min-content;
    height: 2em;
    row-gap: 0em;
  }

`;

const CustomTextWrapper = styled.div`
  padding: 0;
  margin: 0;
  grid-area: 1 / 1/ 2/ 3;
  @media (min-width: 800px){
    grid-area: 1, 1, 2, 2;
  }
`

const StyledThemeToggleButton = styled.div`
  background: ${props => props.theme.secondary};
  color: ${props => props.theme.primary};
  padding: .5em 1em;
  margin-top: .5em;
  z-index: 10;
  cursor: pointer;
  font-weight: bold;
`

const Footer = ({ dispatch, theme }) => {
  return (
    <StyledFooter theme={theme}>
      <CustomTextWrapper>
            <CustomText theme={theme} fontsize="40px">Get Listenin'</CustomText>
      </CustomTextWrapper>
      <FooterLinks theme={theme}>
        <ul className="textLinks">
          <li><b className="linkHeader">Calendars</b></li>
          <li><a className="linkItem" href="https://calendar.google.com/calendar/u/0?cid=ZG1xNmg5bTZqNWJibzI0MGxpYjZjdGFjaWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ">Events Schedule</a></li>
          <li><a className="linkItem" href="https://calendar.app.google/HEBBRTdKXijjjabi9">{"Recording Studio Schedule (Booking info on the Slack!)"}</a></li>
        </ul>
      </FooterLinks>
      <FooterLinks theme={theme}>
          <ul className="textLinks">
            <li><b className="linkHeader">Information</b></li>
            <li><a className="linkItem" href="https://forms.gle/WsKijnnLAykx9BJL9">Website Feedback</a></li>

            <li><a className="linkItem" href="https://publicfiles.fcc.gov/fm-profile/wmuc-fm">FCC Public File</a></li>
            <li><a className="linkItem" href="https://sethtrei.github.io/WMUC-Record-Library/">WMUC Record Library Catalog</a></li>
            <li><a className="linkItem" href="/stream_ripper/">WMUC Show Archive (mp3)</a></li>
            <li><Link className="linkItem" to="/credits">Website Credits</Link></li>
          </ul>
      </FooterLinks>
      <FooterLinks theme={theme}>
        <ul className="textLinks">
          <li><b className="linkHeader">Other Sites</b></li>
          <li><a className="linkItem" href="http://wmucsports.net">WMUC Sports</a></li>
          <li><a className="linkItem" href="https://wmucnews.com/">WMUC News</a></li>
          <li><a className="linkItem" href="/ghost-site/ghost">Ghost (CMS)</a></li>
        </ul>
      </FooterLinks>
      <FooterLinkFlex>
      <FooterSocials>
        <a href="https://twitter.com/wmucradio">
          <FontAwesomeIcon icon={faTwitter} color={theme.secondary} size='2x' />
        </a>
        <a href="https://www.facebook.com/wmucradio">
          <FontAwesomeIcon icon={faFacebook} color={theme.secondary} size='2x' />
        </a>
        <a href="https://www.instagram.com/wmucradio/">
           <FontAwesomeIcon icon={faInstagram} color={theme.secondary} size='2x'/>
        </a>
        <a href="https://www.youtube.com/channel/UCpuwRLrqZ4jmb0ENB6pGWgw">
          <FontAwesomeIcon icon={faYoutube} color={theme.secondary} size='2x' />
        </a>
        {/* <div className="break"></div> */}
        {/* <StyledThemeToggleButton 
        theme={theme}
        onClick={() => {
          dispatch({ type: 'TOGGLE_THEME' })
        }}>
        {theme.isLight ? 'Dark Theme' : 'Light Theme'}
      </StyledThemeToggleButton> */}
      </FooterSocials>
      </FooterLinkFlex>
    </StyledFooter>
  )
}

const mapStateToProps = (state) => {
  const { theme } = state
  
  return { theme }
}

Footer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired
  // {
  //   isLight: PropTypes.bool.isRequired,
  //   primary: PropTypes.string.isRequired,
  //   secondary: PropTypes.string.isRequired
  // }
}

export default connect(mapStateToProps)(Footer);
