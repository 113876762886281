import React, {useState, useEffect} from 'react';
import {PostContainer, FeaturedPostContainer} from '../containers/PostContainer';

import { InstagramEmbed, YouTubeEmbed } from 'react-social-media-embed';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { faFacebook, faTwitter, faInstagram, faYoutube, faTiktok, faSlack} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from 'styled-components';

const Content = styled.div`

  padding-top: 100px;
`;

const SocialPanel = styled.div`

  float: left;
  display: flex;
  margin-right: 10px;
  width: 30%;  
  flex-direction: column;

  @media(max-width: 1000px) {
    float: none;
    width: 100%;
  }

  padding-bottom: 5px;
`;

const HeaderText = styled.h4`
  color: red; 
  background-color: black;
  padding: 5px 0px 5px 10px;
  border-radius: 5px;
  margin-right: 5px;

`;

const TwitterContainer = styled.div`
  width:100%;
  background-color: white;
  padding: 10px 9px 10px 8px;
  border-radius: 2px;

  border: 1px solid #E1E8ED;
  // ^ twitter "extra light grey" color
`;

const LinkBar = styled.div`
  text-align: center;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #E1E8ED;
`;

const SocialBar = () => {

  return(
    <>
      <HeaderText style={{marginTop: "10px", marginLeft: "5px"}}><b>Join Us Here!</b></HeaderText>
      <LinkBar>
        <a href="https://twitter.com/wmucradio"><FontAwesomeIcon icon={faTwitter} color="black" size='3x' style={{padding: "5px"}}/></a>
        <a href="https://www.facebook.com/wmucradio/"><FontAwesomeIcon icon={faFacebook} color="black" size='3x' style={{padding: "5px"}}/></a>
        <a href="https://www.instagram.com/wmucradio/?hl=en"><FontAwesomeIcon icon={faInstagram} color="black" size='3x' style={{padding: "5px"}}/></a>
        <a href="https://www.youtube.com/channel/UCpuwRLrqZ4jmb0ENB6pGWgw"><FontAwesomeIcon icon={faYoutube} color="black" size='3x' style={{padding: "5px"}}/></a>
        <a href="https://www.tiktok.com/@wmucradio"><FontAwesomeIcon icon={faTiktok} color="black" size='3x' style={{padding: "5px"}}/></a>
        <a href="https://join.slack.com/t/wmuc905/shared_invite/zt-2owus8xzp-Y47YOHkoJzGRMmk2B7E0qg"><FontAwesomeIcon icon={faSlack} color="black" size='3x' style={{padding: "5px"}}/></a>
      </LinkBar>
    </>
  )

}


let HomePage = () => {

  var state = { matches: window.matchMedia("(min-width: 420px)").matches };

  return (
    <div>
      <FeaturedPostContainer/>
      <Content>
        <SocialPanel>
          <HeaderText><b>Check Our Socials</b></HeaderText>
          <InstagramEmbed url="https://www.instagram.com/wmucradio/?hl=en" width={328} style={{width: "100%"}}/>
          {state.matches ? 
            <TwitterContainer>
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="wmucradio"
                tweetLimit="5"
                options={{width:"100%", height: 400}}
              /> 
            </TwitterContainer>
          : null}
        <SocialBar/>
        </SocialPanel>
        <PostContainer count="10" page="1"/>
      </Content>
    </div>
  )
}


export default HomePage;
